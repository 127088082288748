<template>
  <div id="password-page" class="pt-9">
    <app-box width="small">
      <template #legend>Update your password</template>
      <transition name="fade">
        <!-- eslint-disable-next-line vue/no-v-html -->
        <app-form-alert v-if="form.error" type="error" v-html="form.error" />
      </transition>
      <app-form @submit.prevent="submitForm">
        <app-form-field>
          <app-label id="password">
            New password
          </app-label>
          <app-input id="password" v-model="form.fields.password" type="password" autocomplete="new-password" />
        </app-form-field>
        <app-button class="mt-3 transition-colors" :disabled="loading || requiredFieldIsBlank">
          Update
        </app-button>
      </app-form>
    </app-box>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { Util as U } from "@/util";
export default {
  name: "Password",
  data: () => ({
    form: {
      fields: {
        password: "",
      },
      error: "",
    },
  }),
  computed: {
    ...mapState(["loading"]),
    requiredFieldIsBlank() {
      const { password } = this.form.fields;
      return password === "";
    },
  },
  methods: {
    async submitForm() {
      if (this.invalidForm) return;
      const { password } = this.form.fields;
      const urlParams = new URLSearchParams(window.location.hash.split("?")[1]);
      const token = urlParams.get("token");
      const url = "api/auth/password";
      try {
        await U.api({ url, method: "post", body: { password, token } });
        this.$router.push("/login?reset=1");
      } catch (e) {
        this.form.error = `Oops, something went wrong.<br><span class="font-normal">The password could not be updated, please try again later.</span>`;
      }
    },
  },
};
</script>
